<template>
  <div class="q">
      <div class="q_content">
        <div class="q_question">
          <h3>Nqkakf vypros mo ne sega ???</h3>
        </div>
        <div class="q_actions">
          actions
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.q{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.7);
    /* color: azure; */
    justify-content: center;
    align-items: center;
}
.q_content{
  min-width: 25vw;
  min-height: 10vh;
  background: #f3f3f3;
  border-radius: 5px;
}
</style>