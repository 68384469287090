<template>
<!-- <h2>{{user}}</h2> -->
<Modal 
    openButtonName="Нова Бележка" 
    :isActive="activeModal" 
    openButtonColor="#59CD90" 
    @close-modal="closeModal" 
    @open-modal="openModal" 
>
    <template v-slot:modal-header> 
        <h4>Добавяне на бележка</h4>
    </template>
    <template v-slot:modal-content> 
        <Input 
            v-model:data="note.note" 
            type="textarea" 
            label="Описание" 
            name="desc" id="Desc"  
             />
    </template>
    <template v-slot:modal-actions> 
        <Button @button-click="clearSelected" title="Изчисти" color="#FAC05E" type="normal" />  
        <Button @button-click="saveNote(note)" title="Запиши" color="#59CD90" type="normal"  /> 
        
    </template>

</Modal>

<Modal 
    openButtonName="Нова Лична Бележка" 
    :isActive="activeModalP" 
    openButtonColor="#59CD90" 
    @close-modal="closeModalP" 
    @open-modal="openModalP" 
>
    <template v-slot:modal-header> 
        <h4>Добавяне на бележка</h4>
    </template>
    <template v-slot:modal-content> 
        <Input 
            v-model:data="note.note" 
            type="textarea" 
            label="Описание" 
            name="desc" id="Desc"  
             />
    </template>
    <template v-slot:modal-actions> 
        <Button @button-click="clearSelected" title="Изчисти" color="#FAC05E" type="normal" />  
        <Button @button-click="savePersonalNote(note)" title="Запиши" color="#59CD90" type="normal"  /> 
        
    </template>

</Modal>
<Modal 
    openButtonName="" 
    modalType="hiden"
    :isActive="activeModalE" 
    openButtonColor="#59CD90" 
    @close-modal="closeModalE" 
    @open-modal="openModalE" 
>
    <template v-slot:modal-header> 
        <h4>edit</h4>
    </template>
    <template v-slot:modal-content> 
        <Input 
            v-model:data="editNoteData.note.note" 
            type="textarea" 
            label="Описание" 
            name="desc" id="Desc"  
             />
    </template>
    <template v-slot:modal-actions> 
        <!-- <Button @button-click="clearSelected" title="Изчисти" color="#FAC05E" type="normal" />   -->
        <Button @button-click="saveEditedNote(note)" title="Запиши" color="#59CD90" type="normal"  /> 
        
    </template>

</Modal>
<Modal 
    openButtonName="" 
    modalType="hiden"
    :isActive="activeModalEP" 
    openButtonColor="#59CD90" 
    @close-modal="closeModalEP" 
    @open-modal="openModalEP" 
>
    <template v-slot:modal-header> 
        <h4>edit</h4>
    </template>
    <template v-slot:modal-content> 
        <Input 
            v-model:data="editNoteData.note.note" 
            type="textarea" 
            label="Описание" 
            name="desc" id="Desc"  
             />
    </template>
    <template v-slot:modal-actions> 
        <!-- <Button @button-click="clearSelected" title="Изчисти" color="#FAC05E" type="normal" />   -->
        <Button @button-click="savePersonalEditedNote(note)" title="Запиши" color="#59CD90" type="normal"  /> 
        
    </template>

</Modal>
<!-- {{allNotes}} -->
<h2>Общи</h2>
<div class="notes">
    <template  v-for="note in allNotes" :key="note.note.id">
        <div class="note" v-if="note.note.status==='active'">
            <div class="note_content">
                <div class="note_c">
                    <p>{{note.note.note}}</p>
                </div>
                <div class="note_info">
                    <span v-if="note.owner">от {{note.owner}},  </span>
                    <span > дата{{handleTime(note.date)}}  </span>
                </div>
            </div>
            <div class="edit" @click="handleEditNote(note)">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 488.728 488.728" style="enable-background:new 0 0 488.728 488.728;" xml:space="preserve">
                    <g>
                        <path d="M487.147,462.52l-36.4-167.6c0-4.2-2.1-7.3-5.2-10.4l-261.3-261.3c-20-22.9-74.3-38.1-112.4,0l-47.9,47.9   c-31,31-31,81.4,0,112.4l261.3,261.3c2.1,2.1,5.2,4.2,9.4,5.2l168.6,38.5C473.347,490.02,492.347,483.62,487.147,462.52z    M53.047,154.42c-15.6-15.6-15.6-39.6,0-55.2l47.9-47.9c15.2-15.2,40-15.2,55.2,0l238.4,238.4h-27.1c-11.4,0-20.8,9.4-20.8,20.8   v34.3h-34.3c-11.4,0-20.8,9.4-20.8,20.8v26.1L53.047,154.42z M333.047,415.72v-29.2h34.3c18,1.7,20.8-16.5,20.8-20.8v-34.4h29.2   l24,109.3L333.047,415.72z"/>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </div>
            <div class="done" @click="compliteNote(note)">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"  viewBox="0 0 405.272 405.272" style="enable-background:new 0 0 405.272 405.272;" xml:space="preserve">
                    <g>
                        <path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836   c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064   c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"/>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </div>
            
            <!-- <p @click="handleEditNote(note)">edit</p> -->
            <!-- <div class="note_date">{{handleTime(note.date)}}</div>
            <p @click="removeNote(note.id)">Изтрий</p> -->
        </div>
    </template>
</div>
<h2>Лични</h2>
<div class="notes">
    <template  v-for="note in allPersonalNotes" :key="note.note.id">
        <div class="note" v-if="note.note.status==='active'">
            <div class="note_content">
                <div class="note_c">
                    <p>{{note.note.note}}</p>
                </div>
                <div class="note_info">
                    <span v-if="note.owner">от {{note.owner}},  </span>
                    <span > дата{{handleTime(note.date)}}  </span>
                </div>

            </div>
            <div class="edit" @click="handlePersonalEditNote(note)">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 488.728 488.728" style="enable-background:new 0 0 488.728 488.728;" xml:space="preserve">
                    <g>
                        <path d="M487.147,462.52l-36.4-167.6c0-4.2-2.1-7.3-5.2-10.4l-261.3-261.3c-20-22.9-74.3-38.1-112.4,0l-47.9,47.9   c-31,31-31,81.4,0,112.4l261.3,261.3c2.1,2.1,5.2,4.2,9.4,5.2l168.6,38.5C473.347,490.02,492.347,483.62,487.147,462.52z    M53.047,154.42c-15.6-15.6-15.6-39.6,0-55.2l47.9-47.9c15.2-15.2,40-15.2,55.2,0l238.4,238.4h-27.1c-11.4,0-20.8,9.4-20.8,20.8   v34.3h-34.3c-11.4,0-20.8,9.4-20.8,20.8v26.1L53.047,154.42z M333.047,415.72v-29.2h34.3c18,1.7,20.8-16.5,20.8-20.8v-34.4h29.2   l24,109.3L333.047,415.72z"/>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </div>            
            <div class="done" @click="complitePersonalNote(note)">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"  viewBox="0 0 405.272 405.272" style="enable-background:new 0 0 405.272 405.272;" xml:space="preserve">
                    <g>
                        <path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836   c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064   c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"/>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </div>
            <!-- <div class="note_date">{{handleTime(note.date)}}</div>
            <p @click="removePersonalNote(note.id)">Изтрий</p> -->
        </div>
    </template>
</div>
    
</template>
<script>

import useNotes from '../composition/useNotes'
import Modal from '../components/UI/Modal.vue'

import {toRefs,reactive} from 'vue'
import Input from '../components/UI/Input.vue';
import Button from '../components/UI/Button.vue';
export default{
    setup() {
        const { editPersonalNote,editNote,createNote, curentNote, allNotes, removeNote, compliteNote  , createPersonalNote , complitePersonalNote,
        allPersonalNotes , curentPersonalNote, user, removePersonalNote} = useNotes();

        const data = reactive({
            activeModal:false,
            activeModalP:false,
            activeModalE:false,
            activeModalEP:false,
            editNoteData:{},
            note:{
                status:'active',
                note:''
            }
        })

        const closeModal = ()=>{
            data.activeModal = false
            clearSelected()
        }
        const openModal = ()=>{
            data.activeModal = true
        }
        const closeModalP = ()=>{
            data.activeModalP = false
            clearSelected()
        }
        const openModalP = ()=>{
            data.activeModalP = true
        }
        const openModalE = ()=>{
            data.activeModalE = true
        }
        const closeModalE = ()=>{
            data.activeModalE = false
        }
        const openModalEP = ()=>{
            data.activeModalEP = true
        }
        const closeModalEP = ()=>{
            data.activeModalEP = false
        }
        const clearSelected = ()=>{
            data.note={
                status:'active',
                note:''
            }
            data.editNoteData = ''
        }
        const saveNote = (note)=>{
            console.log('com')
            let date = Math.floor(Date.now() / 1000)
            createNote({note,date})
            closeModal()
        }
        const savePersonalNote = (note)=>{
             console.log('pers')
            let date = Math.floor(Date.now() / 1000)
            createPersonalNote({note,date})
            closeModalP()
        }
        const handleTime = (text)=>{
            let textToInt = Math.round(text)*1000
            let data = new Date(textToInt).toLocaleString("bg-BG")
            return data
        }
        const handleEditNote = (note)=>{
            console.log(note)
            data.editNoteData = note
            openModalE()
        }
        const handlePersonalEditNote = (note)=>{
            console.log(note)
            data.editNoteData = note
            openModalEP()
        }
        const saveEditedNote = ()=>{
            console.log('save edit note',data.editNoteData,data.editNoteData.note.note)
            editNote(data.editNoteData,data.editNoteData.note.note)
            closeModalE()
        }
        const savePersonalEditedNote = ()=>{
            console.log('save edit personal note',data.editNoteData,data.editNoteData.note.note)
            editPersonalNote(data.editNoteData,data.editNoteData.note.note)
            closeModalEP()
        }

        // const compliteNoteWithTimer = (note)=>{
        //     setTimeout(
        //         compliteNote(note),
        //         8000
        //     )
        // }
        

        
        return {
            ...toRefs(data),
            createNote,
            curentNote,
            allNotes,
            allPersonalNotes,
            curentPersonalNote,
            complitePersonalNote,
            removeNote,
            closeModal,
            openModal,
            clearSelected,
            saveNote,
            handleTime,
            compliteNote,
            savePersonalNote,
            user,
            openModalP,
            closeModalP,
            removePersonalNote,
            editNote,
            handleEditNote,
            openModalE,
            closeModalE,
            saveEditedNote,
            openModalEP,
            closeModalEP,
            handlePersonalEditNote,
            savePersonalEditedNote,
        };
    },
    components: { Modal, Input, Button }
}
</script>
<style scoped>
.notes{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.note {
    min-height: 50px;
    display: flex;
    margin-bottom: 1rem;
    -webkit-box-shadow: 0px 13px 11px -11px #000000;
    box-shadow: 0px 13px 11px -11px #000000;
    border-radius: 2px;
    overflow: hidden;
}
.note_content{
    flex:12 1 0;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    padding: .5rem;
}
.done{
    flex: 1 1 0;
    background: green;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.2rem 1.3rem;
}
.edit{
    flex: 1 1 0;
    background: orange;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.2rem 1.3rem;
}
.note_date{
    flex: 3 1 0;
    background: #f1f1f1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
}

path{
    fill:white
}
</style>