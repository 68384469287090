import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import Dashboard from '../views/Dashboard.vue'
import Clients from '../views/Clients.vue'
import User from '../views/User.vue'
import Prices from '../views/Prices.vue'
import Stats from '../views/Stats'
// import DatePicker from '../views/DatePicker'
import Notes from '../views/Notes'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  { 
    path: '/user/:userId',
    name:'User',
    component: User,
    props:true
  },
  {
    path:'/prices',
    name: 'Prices',
    component: Prices
  },
  {
    path:'/Stats',
    name: 'Stats',
    component: Stats
  },
  // {
  //   path:'/datepicker',
  //   name: 'DatePicker',
  //   component: DatePicker
  // },
  {
    path:'/notes',
    name: 'Notes',
    component: Notes
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
