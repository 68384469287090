<template>
    <div class="date_picker_wrap">
        <div class="date_picker">
            <div class="selected_date" @click="togglePicker()">
                <h2>{{displayDate}}</h2>
            </div>
            <div class="selecter_pad" v-bind:class="(togglePickerState)?'open':'close'">
                <div class="dates">
                    <div class="prev_month arrow" @click="prevMonth()"><p>-</p></div>
                    <div class="month"><p>{{displaySelectMonth}} {{displaySelectYear}}</p></div>
                    <div class="next_month arrow" @click="nextMonth()" ><p>+</p>  </div>
                </div>
                <div class="days">
                    <div class="weack_days">
                        <div class="w_day day"><p>П</p></div>
                        <div class="w_day day"><p>В</p></div>
                        <div class="w_day day"><p>С</p></div>
                        <div class="w_day day"><p>Ч</p></div>
                        <div class="w_day day"><p>П</p></div>
                        <div class="w_day day"><p>С</p></div>
                        <div class="w_day day"><p>Н</p></div>
                    </div>
                    <div class="act_weack_days">
                        <div 
                        class="day" 
                        v-for="day,index in monthDays" 
                        v-bind:key="index" @click="selectDate(day)" 
                        v-bind:class="[((day===selectedDay& month === selectedMonth))?'active':'else_class',((day===''))?'light_bg ':'pointer hover']"  
                       
                        ><p>{{day}}</p></div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"

export default { 
    props:{
        date:String,
    },
    emits:['update:date'],
    setup(props,{emit}){
        const data = reactive({
            date:'',
            displayDate:'',
            month:'',
            selectedMonth:'',
            displaySelectMonth:'',
            displaySelectYear:'',
            selectedDate:'',
            months:[
                'Януари',
                'Февруари',
                'Март',
                'Април',
                'Май',
                'Юни',
                'Юли',
                'Август',
                'Септември',
                'Октомври',
                'Ноември',
                'Декември'
            ],
            // monthDays:[]
            monthDays:['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',],
            selectedDay:'',
            togglePickerState :false
        })

        onMounted(()=>{
            data.date = new Date()
            setTimeout(()=>{
                if(props.date){
                    data.date = new Date(props.date)
                }
                data.displayDate = data.date.getDate()+' / '+Number(data.date.getMonth()+1)+' / ' + data.date.getFullYear()
                data.selectedDate = data.displayDate
                data.displaySelectMonth = data.months[data.date.getMonth()]
                data.displaySelectYear= data.date.getFullYear()
                data.month = data.date.getMonth()
                data.selectedMonth = data.month
                data.testDay = new Date('2022-2-1').getDay()
                fillDays()
                data.selectedDay = data.date.getDate()
                
                },1)
        })

        // watchEffect(()=>data.date, ()=>{
        //     data.displayDate = data.date.getDate()+' / '+Number(data.date.getMonth()+1)+' / ' + data.date.getFullYear()
        //     data.selectedDate = data.displayDate
        //     data.displaySelectMonth = data.months[data.date.getMonth()]
        //     data.displaySelectYear= data.date.getFullYear()
        //     data.month = data.date.getMonth()
        //     data.selectedMonth = data.month
        // })

        const selectDate = (day)=>{
            if (day == ''){
                return
            }
            data.date = new Date(data.displaySelectYear,Number(data.month),day,23, 59, 59)
            data.displayDate = data.date.getDate()+' / '+Number(data.date.getMonth()+1)+' / ' + data.date.getFullYear()
            data.selectedDay = data.date.getDate()
            data.selectedMonth = data.month
            console.log('data.date:',data.date)
            togglePicker()
            emit('update:date',data.date.toDateString())
        }

        const fillDays = ()=>{
            data.monthDays=['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',]
            let monthDays = 0
            let day = new Date(data.displaySelectYear+'-'+Number(data.month+1)+'-1').getDay()
            monthDays = new Date(data.displaySelectYear,Number(data.month+1),0).getDate()
            console.log(monthDays)
            let start = 1
            if(day == 0){
                day=6
            }else{
                day= day-1 
            }
            monthDays = monthDays+day
            for (let index = day; index  <monthDays; index++) {
                data.monthDays[index] = start
                start++
            }
        }

        const nextMonth = ()=>{
            data.month++
            if(data.month>11){
                data.month = 0
                data.displaySelectYear++
            }
            data.displaySelectMonth = data.months[data.month]
            fillDays()
        }
        const prevMonth = ()=>{
            data.month--
            if(data.month<0){
                data.month = 11
                data.displaySelectYear--
            }
            data.displaySelectMonth = data.months[data.month]
            fillDays()
        }
        const togglePicker = ()=>{
            data.togglePickerState = !data.togglePickerState
            // console.log(data.togglePickerState)
        }

        return{ 
            ...toRefs(data),
            selectDate,
            nextMonth,
            prevMonth,
            togglePicker

        }
    }

}
</script>

<style scoped>
*{
    border-radius: 4px;
}

.date_picker_wrap{
    position:relative;
    height: 30px;
    width: 260px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.selected_date{
    width: 260px;
}
.selected_date > h2{
    /* width: 100%; */
    margin:0px;
    background: white;;
}
.dates{
    display: flex;
    flex-wrap: wrap;
    max-width: 260px;
    align-items: center;
    justify-content: space-around;


}
.dates > div{
    margin: 1px;
    display: flex;
    background: rgba(0, 0, 0, 0.22);
    align-items: center;
    justify-content: center;
}
.dates > div >p{
    margin: 0;
}
.arrow{
    width: 20%;
    height: 30px;
   
}
.month{
    width: 57%;
    height: 30px;
    /* background-color: lightblue; */
    align-items: center;
    justify-content: center;

}
.weack_days, .act_weack_days{
    display: flex;
    flex-wrap: wrap;
    max-width: 260px;
    align-items: center;
    justify-content: center;
}
.day{
    display: flex;
    flex: 1 0 13%; /* explanation below */
    margin: 1px;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.day >p{
    margin: 0;
}

.active{
    background: green;
}
.open{
    display: block;
}
.close{
    display:none;
}
.selecter_pad{
    position:absolute;
    top:31px;
    left: 0;    
    background: white;
    border: 1px solid lightgrey;
    overflow: hidden;
}
.act_weack_days > .day{
    background: rgba(0, 0, 0, 0.2);
}
.w_day{
    background: rgba(0, 0, 0, 0.14);
}
.light_bg{
    background: rgba(0, 0, 0, 0.05) !important;
}
.pointer{
    cursor: pointer;
}
.active{
    background: black !important;
    color:white;
}
.hover:hover{
    background: black !important;
    color:white;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.36); 
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.36);
}

</style>s