<template>
  <Notifications/>
  <Question></Question>
  <Navigation/>
  <div class="content">
      <router-view/>
  </div>
</template>

<script>
import {onMounted} from 'vue'
import Navigation from './components/Navigation.vue'
import Notifications from './components/Notifications'
import useUiState from'./composition/useUiState'
import useAuth from './composition/useAuth'
import Question from './components/UI/Question.vue'
// import useTasks from './composition/useTasks'
export default {
  name:'App',
  components:{
    Notifications,
    Question,
    Navigation
  },
  setup(){  
    let {isUser}=useAuth()
    // let {autoUpdateTasks}=useTasks() 
    let {isLoading,toggleMenu,isMenuOpen}=useUiState()
    onMounted(()=>{
      isUser()
      // autoUpdateTasks()
    })
    return {isLoading,toggleMenu,isMenuOpen}
  }
}
</script>

<style>
body{
  margin: 0;
}
*{
  font-family: 'Exo 2', sans-serif;
}
#app{
  display: flex;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
}
.content{
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin-left: 150px;
}
</style>
