import { reactive, toRefs } from 'vue'
import {db} from '../firebase'
import useAuth from './useAuth'

let {user}=useAuth()
const state = reactive({
    curentNote:'',
    allNotes:[
        //  { "id": "EbYUriBEebB8wH5yIVmD", "date": 1644844541, "note": "888888", "status":"actove" }, { "id": "snD7yMMGseWCta4FDku6", "date": 1644844537, "note": "44444", "status":"actove" }, { "id": "tjurOoUfcgYUV8QZ3YX0", "note": "12345", "date": 1644844526, "status":"actove" }
        
        ],
    curentPersonalNote:'',
    allPersonalNotes:[],
    notesInfo:0,
    personalNotesInfo:0
});


const createNewNote  = (noteData)=>{
    return new Promise((resolve, reject)=>{
        db.collection('notes').add({
            ...noteData,
            createdBy:user.value.id,
            owner:user.value.name
        }).then((doc)=>{
            doc.get().then((note)=>{
                console.log(note)
                state.curentNote=note
                // addNotification('Успешно добавена задача!','success',7000)
                resolve(note)
            })
            
        }).catch((error)=>{
            // addNotification(error.message,'dangerous',7000)
            reject(error)
        })

    })
}

const createNewPersonalNote  = (noteData)=>{
    const userId = user.value.id
    console.log(userId.toString(),userId)

    return new Promise((resolve, reject)=>{
        db.doc('person/'+userId.toString()).collection('notes').add({
            ...noteData,
        }).then((doc)=>{
            doc.get().then((note)=>{
                console.log(note)
                state.curentPersonalNote=note
                // addNotification('Успешно добавена задача!','success',7000)
                resolve(note)
            })
            
        }).catch((error)=>{
            // addNotification(error.message,'dangerous',7000)
            reject(error)
        })

    })
}

export default function useNotes(){

    const createNote = (noteData)=>{
        createNewNote(noteData)
        .then(console.log('omm'))
        .catch(console.log('off'))
    }
    const createPersonalNote = (noteData)=>{
        createNewPersonalNote(noteData)
        .then(console.log('ommp'))
        .catch(console.log('offp'))
    }

    const subNotes = ()=>{
        state.notesInfo=0,
        state.personalNotesInfo=0
        const userId = user.value.id
        db.collection("notes")
        .onSnapshot((result)=>{
            state.allNotes=[]
            state.notesInfo = 0
            result.forEach( (doc)=>{
                let data = doc.data()
                state.allNotes.push({
                    id:doc.id,
                    ...data
                })
                console.log(data)
                if(data.note.status === 'active'){
                    state.notesInfo= state.notesInfo +1
                }
                
            })
        })
        if(userId !=''){ 
            db.doc('person/'+userId.toString()).collection('notes')
            .onSnapshot((result)=>{
                state.allPersonalNotes=[]
                state.personalNotesInfo = 0
                result.forEach( (doc)=>{
                    let data = doc.data()
                    state.allPersonalNotes.push({
                        id:doc.id,
                        ...data
                    })
                    console.log(data)
                    if(data.note.status === 'active'){
                        
                        state.personalNotesInfo = state.personalNotesInfo +1
                    }
                })
            } )
        }
       
        console.log('notes sub')
    }

    const compliteNote = (note)=>{
        console.log(note)
        let updateNote={
            note:{note : note.note.note ,status : 'complited',complitedBy:user.value.name,data:new Date().toLocaleString()}
        }
        db.collection('notes').doc(note.id).update({
            ...updateNote
        })
        .then(()=>{
            // state.notesInfo = state.notesInfo - 1
            // addNotification('Успешно завършена бележка!','success',7000)
        }).catch(()=>{
            // addNotification(error.message,'dangerous',7000)
        })
    }
    const complitePersonalNote = (note)=>{
        console.log('пн',note)
        const userId = user.value.id
        let updateNote={
            note:{note : note.note.note ,status : 'complited',data:new Date().toLocaleString()}
        }
        // console.log('notes/'+userId+'/notes/'+note.id)
        db.doc('person/'+userId+'/notes/'+note.id).update({
            ...updateNote
        })
        .then(()=>{
            // state.peronalNotesInfo = state.peronalNotesInfo - 1
            // addNotification('Успешно завършена бележка!','success',7000)
        }).catch((err)=>{
            console.log(err)
            // addNotification(error.message,'dangerous',7000)
        })
    }

    const removeNote =(id)=>{
        db.collection('notes').doc(id).delete().then(()=>{
            // console.log('успешно премахната бележка')
        }).catch(()=>{
            // console.log('грешка при премахане на бележка',error)
        })
    }
    const removePersonalNote =(id)=>{
        console.log('remove personal note with id',id)
        const userId = user.value.id
        db.doc('person/'+userId).collection('notes').doc(id).delete().then(()=>{
            // console.log('успешно премахната бележка')
        }).catch(()=>{
            // console.log('грешка при премахане на бележка',error)
        })
    }

    const editNote = (note,newNote)=>{
        console.log(note,newNote)
        let updateNote={
            ...note,
            note:{
                note:newNote,
                status:'active'
            }
            }
        console.log('un',updateNote)
        db.collection('notes').doc(note.id).update({
            ...updateNote
        })
        .then(()=>{
            // state.notesInfo = state.notesInfo - 1
            // addNotification('Успешно завършена бележка!','success',7000)
        }).catch(()=>{
            // addNotification(error.message,'dangerous',7000)
        })
    }

    const editPersonalNote = (note,newNote)=>{
        const userId = user.value.id
        console.log(note,newNote)
        let updateNote={
            ...note,
            note:{
                note:newNote,
                status:'active'
            }
            }
        console.log('un',updateNote)
        db.doc('person/'+userId).collection('notes').doc(note.id).update({
            ...updateNote
        })
        .then(()=>{
            // state.notesInfo = state.notesInfo - 1
            // addNotification('Успешно завършена бележка!','success',7000)
        }).catch(()=>{
            // addNotification(error.message,'dangerous',7000)
        })
    }

    return{
        ...toRefs(state),
        user,
        createNote,
        subNotes,
        removeNote,
        compliteNote,
        createPersonalNote,
        complitePersonalNote,
        removePersonalNote,
        editNote,
        editPersonalNote
    }
}