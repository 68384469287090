import { reactive, toRefs } from 'vue';
// import router from '../router/index'
import {db} from '../firebase'
// import useUiState from './useUiState'
// import useNotifications from './useNotifications' 
// import useClients from './useClients'
// import useAuth from './useAuth'


const state = reactive({
    curentService:'',
    allServices:[],
    allServicesList:[]

});

const createNewService  = (serviceData)=>{
    return new Promise((resolve, reject)=>{
        db.collection('services').add({
            ...serviceData
        }).then((doc)=>{
            doc.get().then((service)=>{
                state.curentService=service
                // addNotification('Успешно добавена задача!','success',7000)
                resolve(service)
            })
            
        }).catch((error)=>{
            // addNotification(error.message,'dangerous',7000)
            reject(error)
        })

    })
}

export default function usePrices(){

    const createService = (serviceData)=>{
        createNewService(serviceData)
        .then(console.log('omm'))
        .catch(console.log('off'))
    }

    const subServices = ()=>{
        db.collection("services")
        .onSnapshot((result)=>{
            state.allServices=[]
            state.allServicesList=[]
            result.forEach( (doc)=>{
                let data = doc.data()
                state.allServices.push({
                    id:doc.id,
                    ...data
                }),
                state.allServicesList.push({
                    name:'',
                    value:data.name +' - '+ data.price
                })
            })
        })
        console.log('services sub')
    }

    const removeService =(id)=>{
        db.collection('services').doc(id).delete().then(()=>{
            console.log('успешно премахната услуга')
        }).catch((error)=>{
            console.log('грешка при премахане на услугата',error)
        })
    }

    return{
        ...toRefs(state),
        createService,
        subServices,
        removeService
    }
}