<template>
    {{startDate}}-{{endDate}}
    <p>Статистика</p>
    От дата:<DatePicker v-model:date="startDate" ></DatePicker>
    До дата:<DatePicker v-model:date="endDate" ></DatePicker>
    <h2>Печалба от</h2>
    <p> Услуги: {{totalServices}} Части: {{totalParts}} Обща:{{total}}</p>
    <div class="graph">
        <h2>Общо: {{caunt}}</h2>
        <div class="row type">
            <p :style="{width: typesCaunt.noteboock*procent*2 + '%'}">Лаптоп: {{typesCaunt.noteboock}}</p>
        </div>
        <div class="row type">
            <p :style="{width: typesCaunt.phone*procent*2 + '%'}">Телефон: {{typesCaunt.phone}}</p>
        </div>
        <div class="row type">
            <p :style="{width: typesCaunt.pc*procent*2 + '%'}">Настолен компютър: {{typesCaunt.pc}}</p>
        </div>
        <div class="row type">
            <p :style="{width: typesCaunt.printer*procent*2 + '%'}">Принтер: {{typesCaunt.printer}}</p>
        </div>
        <div class="row type">
            <p :style="{width: typesCaunt.tablet*procent*2 + '%'}">Таблет: {{typesCaunt.tablet}}</p>
        </div>
        <div class="row type">
            <p :style="{width: typesCaunt.other*procent*2 + '%'}">Други: {{typesCaunt.other}}</p>
        </div>
    </div>
    <h2>Търсене по действия</h2>
    <AutoComliteInput v-model:data="customSearch" :label="' действие'" name="custom" id="custom" :options="allServicesList" />
    <p>Брой съвпадения: {{customC}}</p>
    <h2>ДАННИ ОТ СЕРВИЗА</h2>
    <Task v-for="task in customs" :key="task.id" :task="task" />
</template>
<script>
// import { toRefs, reactive, watch } from '@vue/reactivity';
import {  onMounted, reactive, toRefs, watchEffect,watch} from 'vue'
import useTasks from "../composition/useTasks";
import DatePicker from '../components/UI/DatePicker.vue';
import usePrices from '../composition/usePrices';
import AutoComliteInput from '../components/UI/AutoComliteInput';
import Task from '../components/Task.vue'

export default{
    components:{ DatePicker,AutoComliteInput,Task },
    setup(){
        const {tasks}=useTasks()
        const {allServicesList}=usePrices()

        const data = reactive({
            filtredTasks:[],
            caunt:0,
            startDate:'',
            startDateIso:'',
            endDate:'',
            endDateIso:'',
            type:'',
            totalServices:'',
            totalParts:'',
            total:'',
            typesCaunt:{
                noteboock:'',
                phone:'',
                printer:'',
                pc:'',
                tablet:'',
                other:''
            },
            procent:'',
            customSearch:'',
            customs:[],
            customC:0
        })

        onMounted(()=>{
            let date = new Date()
            data.endDate = new Date().toDateString()
            data.endDateIso = new Date().toISOString()

            if(date.getMonth == 0){
                data.startDate = new Date(date.getFullYear(),11,date.getDate()).toDateString()
                data.startDateIso = new Date(date.getFullYear(),11,date.getDate()).toISOString()

            }else{
                data.startDate = new Date(date.getFullYear(),date.getMonth()-1,date.getDate()).toDateString()
                data.startDateIso = Date.parse(data.startDate)/1000
            }
        })

      

        watch(()=>data.startDate,()=>{data.startDateIso = Date.parse(data.startDate)/1000 + 86400,getCaunt(),console.log('start date cange to :',data.startDate)})
        watch(()=>data.endDate,()=>{data.endDateIso = Date.parse(data.endDate)/1000 + 86400,getCaunt(),console.log('end date cange to :',data.endDate)})
        watch(()=>data.customSearch,()=>{getCaunt()})


        const getCaunt = ()=>{
            data.customs=[]
            data.customC=0
            data.caunt=0
            data.totalServices=0
            data.totalParts=0
            data.total=0
            data.typesCaunt={
                noteboock:0,
                phone:0,
                printer:0,
                pc:0,
                tablet:0,
                other:0
            }
             tasks.value.forEach((task) => {
                console.log('c',data.caunt)
                if(Number(task.updatedAt)>=Number(data.startDateIso) && Number(task.updatedAt)<=Number(data.endDateIso)){
                    data.caunt = Number(data.caunt) +1 
                    if(task.prices!={}&& task.prices != null){
                        console.log('in',task.prices)
                        if(task.prices.servicesPrice ){
                            data.totalServices = Number(data.totalServices) + Number(task.prices.servicesPrice)
                        }
                        if(task.prices.componentsPrice){
                            data.totalParts= Number(data.totalParts) + Number(task.prices.componentsPrice)
                        }
                        console.log(task)
                        console.log('services',data.totalServices,'total',data.total,'parts',data.totalParts)
                        // data.totalServices = Number(data.totalServices) + Number(task.prices.servicesPrice)
                        // data.totalParts = Number(data.totalParts) + Number(task.prices.componentsPrice)
                        data.total = Number(data.totalParts) + Number(data.totalServices)
                        // console.log('DATA: ' + data.totalServices + " " + data.totalParts + " " + data.total)
                    }
                    if(data.customSearch !=''){
                        task.history.forEach((item)=>{
                            
                            if(item.changetTo.toLowerCase().includes('aweiting')&&item.message.toLowerCase().includes(data.customSearch.toLowerCase())){
                                console.log('omm',item.message.toLowerCase(),data.customSearch.toLowerCase())
                                data.customs.push(task)
                                data.customC = data.customC+1
                            } 
                        })
                    }
                    if(task.type){
                        // console.log(task.type)
                        // option.name.toLowerCase().includes(props.data.toLowerCase())||option.value.toLowerCase().includes(props.data.toLowerCase())
                        if(task.type.toLowerCase().includes('лаптоп')){
                            data.typesCaunt.noteboock = Number(data.typesCaunt.noteboock) + 1
                        }else if(task.type.toLowerCase().includes('телефон')){
                            data.typesCaunt.phone = Number(data.typesCaunt.phone) + 1
                        }else if(task.type.toLowerCase().includes('принтер')){
                            data.typesCaunt.printer = Number(data.typesCaunt.printer) + 1
                        }else if(task.type.toLowerCase().includes('настолен компютър')){
                            data.typesCaunt.pc = Number(data.typesCaunt.pc) + 1
                        }else if(task.type.toLowerCase().includes('таблет')){
                            data.typesCaunt.tablet = Number(data.typesCaunt.tablet) + 1
                        }else{
                            data.typesCaunt.other = Number(data.typesCaunt.other) + 1
                        }
                    }
                }
                

            });
            // console.log(Number(data.caunt))
            // data.caunt = Number(data.caunt)-1
            data.procent = 100/Number(data.caunt)
    
        }

        watchEffect(()=>tasks.value,getCaunt())

        return {
            ...toRefs(data),
            tasks,
            allServicesList,
            getCaunt
        }
    }
}



</script>


<style scoped>
.type > p{
    display: block;
    background: darkgray;
    padding: 10px;
}

</style>